@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  padding: 0 7vw;
  margin: 0 auto;
  max-width: 1440px;
}

body {
  @apply bg-aumniBlack text-white;
}

header {
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
  max-width: 76.4em;
  margin: 0 auto;
}

